import { Container, Grid, Link, Typography } from "@mui/material"


export const Contacto = () => {
    //<iframe width="600" height="450" style={{border:0, borderRadius: 25}} allowFullScreen={true} src="https://www.google.com/maps/embed/v1/place?q=Masajes%20Xiang%20Shou&key=AIzaSyD_nxRAAlP1LJ3H10idWiOWCGED1-QoVj8"></iframe>

    return <Container>
        <Typography variant="h3" color="white" sx={{mb:4}}>Contacto</Typography>
        <Typography variant="body1" sx={{mb:8}} color="white">Encuéntranos en el corazón de Barcelona, a solo unos pasos de las paradas de metro de Entença y Rocafort</Typography>
        <Grid container spacing={8}>
            <Grid item xs={12} md={8}><iframe src="https://snazzymaps.com/embed/577913" width="100%" height="475px" style={{border:0, borderRadius: 25}} allowFullScreen={true}></iframe></Grid>
            <Grid item md={4} style={{textAlign: "start", color:"white"}}>
            <Typography variant="h4" color="secondary" sx={{ mt: 0, fontFamily: "'go3v2', cursive" }}>Horario</Typography>
            <pre>LUN-SAB     9:30-21:30<br />DOMINGO    10:30-20:30</pre>
            <Typography variant="h4" color="secondary" sx={{ mt: 4, fontFamily: "'go3v2', cursive" }}>Donde estamos</Typography>
            <pre>Carrer Valencia 57<br/>Barcelona<br/>
            <Link href="https://www.google.com/maps/dir/?api=1&destination=Carrer+Valencia+57+08015+Barcelona" target="_blank" color="secondary">Como llegar</Link></pre>
            <pre>Cerca del metro de Rocafort L1<br />y Entença L5</pre>
            <Typography variant="h4" color="secondary" sx={{ mt: 4, fontFamily: "'go3v2', cursive" }}>Telefono</Typography>
            <pre>605 245 681 <Link href="tel:+34605245681" color="secondary">Llamar</Link> <Link href="https://wa.link/kffk9v" target="_blank" color="secondary">WhatsApp</Link><br />931 050 063 <Link href="tel:+34931050063" color="secondary">Llamar</Link></pre>
            </Grid>
        </Grid> 
    </Container>
}


