import { useTheme } from "@emotion/react";
import { Container, ImageList, ImageListItem, Typography, useMediaQuery } from "@mui/material"


export const Galeria = () => {

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    const itemData = [
        {
            img: '/galeria/0.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/1.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/z.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/y.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/v.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/x.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/w.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/a.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/b.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/c.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/d.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/e.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/g.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/h.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/i.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/k.jpg',
            title: 'Masajistas',
        },
        {
            img: '/galeria/l.jpg',
            title: 'Masajistas',
        }
    ];

    return <Container  sx={{mb: 8}}>
        <Typography variant="h3" sx={{mb:4}} color="white">Masajistas</Typography>
        <Typography variant="body1" sx={{mb:8}} color="white">Nuestras talentosas masajistas, te guiarán hacia un estado de plenitud, fusionando la gracia de sus movimientos con la profesionalidad que define nuestra experiencia única en masajes</Typography>
        <ImageList variant="masonry" sx={{
    columnCount: {
      xs: '2 !important',
      sm: '2 !important',
      md: '3 !important',
      lg: '4 !important'
    },
  }} gap={8}>
        {itemData.map((item) => (
            <ImageListItem key={item.img}>
                <img
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    style={{borderRadius: 12}}
                    loading="lazy"
                />
            </ImageListItem>
        ))}
    </ImageList></Container>
}